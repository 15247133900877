<script lang="ts" setup>
    const { $preview } = useNuxtApp()

    interface Props {
        block: AccordionBlock
        index?: number
        total?: number
    }

    const props = withDefaults(defineProps<Props>(), {
        index: 0,
        total: 1,
    })

    const activeItem = defineModel('activeItem')
    const content = ref()

    const toggleActive = () => {
        activeItem.value = props.block.id == activeItem.value ? null : props.block.id
    }

    watch(activeItem, (activeItemId) => {
        if (activeItemId == props.block.id) {
            content.value.style.height = 0

            nextTick(() => (content.value.style.height = content.value.scrollHeight + 'px'))
        } else {
            content.value.style.height = content.value.scrollHeight + 'px'
            nextTick(() => (content.value.style.height = 0))
        }
    })
</script>

<template>
    <div
        class="accordion-item group mt-[-1px]"
        :class="{ 'opacity-50': !block.title, active: block.id === activeItem }"
        v-if="block.title || $preview"
    >
        <h2
            @click="toggleActive"
            class="group/title relative flex cursor-pointer items-center justify-between border-b border-t border-dark py-2 text-2xl uppercase hover:text-medium sm:text-3xl md:py-4 md:text-5xl"
        >
            <span class="block max-w-[1200px] transition">
                {{ block.title ?? 'Accordion Title...' }}
            </span>

            <button
                v-for="i in 2"
                :class="{
                    'bottom-0 after:rotate-[135deg] group-hover/title:bottom-4 group-hover/title:right-2 group-[.active]:opacity-0 md:bottom-6':
                        i == 1,
                    'top-5 opacity-0 after:-rotate-45 group-hover/title:-right-2 group-hover/title:top-5 group-[.active]:opacity-100 md:top-4 md:group-hover/title:top-2':
                        i == 2,
                }"
                class="icon-arrow absolute right-0 h-[20px] w-[20px] -translate-y-full border-0 transition-all"
            ></button>
        </h2>

        <div ref="content" class="h-0 overflow-hidden transition-[height]">
            <div
                v-for="content in block.content"
                class="raw-content pt-4 text-xl"
                :class="{ 'block-gap-b': index + 1 !== total }"
            >
                <div
                    v-if="content.type === 'text'"
                    v-html="content.text ?? 'Content...'"
                    class="max-w-[350px]"
                />

                <div
                    v-else-if="content.type === 'text_form'"
                    class="block-gap-b flex flex-col justify-between pt-4 md:flex-row"
                >
                    <div v-html="content.text ?? 'Content...'" class="max-w-[350px]" />

                    <div class="basis-1/2">
                        <FormDisplay v-if="content.form" :handle="content.form.handle" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
    .expand-leave-active,
    .expand-enter-active {
        overflow: hidden;
    }

    .expand-enter-to,
    .expand-leave-from {
    }

    .expand-enter-from,
    .expand-leave-to {
        height: 0;
    }

    .answer:last-child {
        margin-bottom: 0;
    }
</style>
