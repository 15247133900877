<script lang="ts" setup>
    const { $preview } = useNuxtApp()

    defineProps<{
        block: LogoBlock
    }>()
</script>

<template>
    <div class="logo flex aspect-square max-w-[352px] items-center justify-center bg-white">
        <div
            class="mt-0 size-full max-h-full max-w-full scale-[0.6] object-contain"
            v-if="block.logo_image || $preview"
        >
            <a :href="block.link" target="_blank" class="contents">
                <img :src="block.logo_image.url" :alt="block.logo_image.alt" class="logo-image" />
            </a>
        </div>
    </div>
</template>
