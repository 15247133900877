<script lang="ts" setup>
    import { OffsetGroup } from '~/data/OffsetGroup'
    import { OffsetItem } from '~/data/OffsetItem'

    defineProps<{
        block: TwoColumnTextBlock
    }>()

    const offset = new OffsetGroup(new OffsetItem(3, 3), new OffsetItem(2, 1), new OffsetItem(0, 1))
</script>

<template>
    <div class="layout-grid block-gap-y text-xl">
        <div class="gap-16 md:flex" :class="offset.classes">
            <div
                data-aos="fade-up"
                class="content-area lg:max-w-[360px]"
                v-html="block.column_one"
            />

            <div
                data-aos="fade-up"
                data-aos-delay="100"
                class="content-area mt-4 md:mt-0 lg:max-w-[360px]"
                v-html="block.column_two"
            />
        </div>
    </div>
</template>

<style scoped>
    .content-area {
        > :not(:first-child) {
            @apply mt-4;
        }

        > :not(:last-child) {
            @apply mb-4;
        }
    }
</style>
