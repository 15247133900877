<script lang="ts" setup>
    import { OffsetGroup } from '~/data/OffsetGroup'

    defineProps<{
        block: LinksBlock
    }>()

    const offset = new OffsetGroup()
</script>

<template>
    <div class="layout-grid block-gap-y">
        <ul :class="offset.classes">
            <li
                v-for="(link, i) in block.links"
                data-aos="fade-up"
                :data-aos-delay="i * 100"
                class="border-b border-t border-dark text-2xl uppercase sm:text-3xl md:text-5xl"
            >
                <a
                    :href="link.link"
                    target="_blank"
                    class="group flex justify-between py-2 hover:text-medium md:py-4"
                >
                    <span class="transition hover:text-medium">{{ link.label }}</span>

                    <button
                        class="icon-arrow relative left-0 top-0 block origin-left rotate-45 border-0 transition-all after:-translate-y-1/2 group-hover:left-[0.1em] group-hover:top-[0.1em]"
                    ></button>
                </a>
            </li>
        </ul>
    </div>
</template>

<style scoped>
    li + li {
        @apply !border-t-0;
    }
</style>
