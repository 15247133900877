<script lang="ts" setup>
    import { Entry } from '~/data/Entry'
    import { OffsetGroup } from '~/data/OffsetGroup'

    interface Props {
        block: FeaturedProjectBlock
        grid?: boolean
    }

    const props = withDefaults(defineProps<Props>(), {
        grid: true,
    })

    const offset = OffsetGroup.fromData(props.block)

    let project: Project | undefined

    if (props.block.project) {
        project = (await Entry.fromId<Project>('projects', props.block.project.id)) as Project
    }
</script>

<template>
    <div v-if="grid && project" class="layout-grid">
        <ProjectItem :project="project" :class="offset.classes" />
    </div>

    <ProjectItem v-else-if="project" :project="project" :class="offset.classes" />
</template>
