<script lang="ts" setup>
    import { OffsetGroup } from '~/data/OffsetGroup'

    const props = defineProps<{
        block: ProjectCallToActionBlock
    }>()

    const projectOffset = OffsetGroup.fromData(props.block)
    const ctaOffset = projectOffset.complementary
</script>

<template>
    <div class="block-gap-y layout-grid last:mb-0">
        <BlockFeaturedProject
            :block="block as FeaturedProjectBlock"
            :grid="false"
            :class="projectOffset.classes"
            data-aos="fade-up"
        />

        <div
            class="cta-wrapper block-gap-t flex items-center justify-center md:mt-0"
            :class="ctaOffset.classes"
        >
            <BlockCallToAction
                :block="block as CallToActionBlock"
                :grid="false"
                class="max-w-[220px] lg:max-w-[330px]"
            />
        </div>
    </div>
</template>

<style scoped>
    .cta-wrapper {
        position: relative;
        top: -20px;
    }
</style>
